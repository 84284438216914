import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import { callAPI, getAnchorTraderDetails } from "../../API/FP/Actions";
import { useAuth } from "../../../context/auth";
import { contextObj } from "../../Common/Constants";

function AnchorTraderReport({ authToken, financeRequestData }) {
  const [anchorTraderDetails, setAnchorTraderDetails] = useState([
    {
      anchorTraderName: "Prime Textiles",
      location: "Warangal",
      anchorTraderGST: "36AAIFP3688H1ZS",
      anchorTraderSector: "Textile Manufacturing",
      anchorTraderPAN: "AAIFP3688H",
      kycCompleted: "Yes",
      bankDetails: "Yes",
    },
  ]);
  const [auth, setAuth] = useAuth();

  const fetchAnchorTraderDetails = useCallback(async () => {
    try {
      const response = await getAnchorTraderDetails(auth?.token);

      if (response.res.data.length !== 0) {
        setAnchorTraderDetails(
          response.res.data.filter(
            (item) =>
              item.anchorTraderGST === financeRequestData?.anchorTraderGst
          )
        );
      }
    } catch (err) {
      console.log("Error", err);
    }
  }, []);

  useEffect(() => {
    // fetchAnchorTraderDetails();
  }, []);
  console.log(
    "11111 anchorTraderDetails",
    anchorTraderDetails,
    typeof financeRequestData?.anchorTraderGstComplianceScore
  );

  const returnValue = (val) => {
    let v1 = parseInt(val);
    if (v1 > 0 && v1 <= 100) {
      return val;
    } else {
      return "0";
    }
  };
  return (
    <>
      <div>
        <RequestInformation style={{ display: "flex" }}>
          {/* First section with the table */}
          <div className="main-content" style={{ flex: 1, padding: "10px" }}>
            <div className="record-details">
              <div className="record-details" style={{ height: "305px" }}>
                <table style={{ width: "120%", height: "100%" }}>
                  <tbody>
                    <tr>
                      <th>Anchor Trader name</th>
                      <td>{anchorTraderDetails[0]?.anchorTraderName}</td>
                    </tr>
                    <tr>
                      <th>Location</th>
                      <td>{anchorTraderDetails[0]?.location}</td>
                    </tr>
                    <tr>
                      <th>Anchor Trader GST</th>
                      <td>{anchorTraderDetails[0]?.anchorTraderGST}</td>
                    </tr>
                    <tr>
                      <th>Anchor Trader sector</th>
                      <td>{anchorTraderDetails[0]?.anchorTraderSector}</td>
                    </tr>
                    <tr>
                      <th>Anchor Trader PAN</th>
                      <td>{anchorTraderDetails[0]?.anchorTraderPAN}</td>
                    </tr>
                    <tr>
                      <th>KYC completed</th>
                      <td>{anchorTraderDetails[0]?.kycCompleted}</td>
                    </tr>
                    <tr>
                      <th>Bank details</th>
                      <td>{anchorTraderDetails[0]?.bankDetails}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {/* Second section with GST Compliance Score */}
          <div className="main-content" style={{ flex: 1, padding: "10px" }}>
            <div
              style={{
                border: "1px solid rgba(0, 0, 128, 0.20)",
                borderRadius: "2px",
                padding: "10px",
                width: "80%",
                marginLeft: "20%",
              }}
            >
              <p
                style={{
                  color: "rgba(97, 94, 131, 1)",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                GST Compliance Score
              </p>
              <Divider variant="middle" />
              {/* Display custom CircleCustom component */}
              <CircleCustom score="90" text="CRE Score" />
              <div className="bullet-points-list">
                <ul
                  style={{ textAlign: "center", listStylePosition: "inside" }}
                >
                  <li>{"< 70 - Ineligible Cut-Off "}</li>
                  <li>{"> 70 - Eligible Cut-Off "}</li>
                </ul>
              </div>
            </div>
          </div>
        </RequestInformation>
      </div>
      {/* Footer section (commented out in your code) */}
      {/* <div style={{ marginTop: "30vh" }}>
    <Footer />
  </div> */}
    </>
  );
}

export default AnchorTraderReport;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 100%;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
