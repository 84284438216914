import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  Snackbar,
  TextField,
  Typography,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InputLabel, { inputLabelClasses } from "@mui/material/InputLabel";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../context/auth";
import EditIcon from "@mui/icons-material/Edit";
import {
  generateOTPContextObjController,
  gstCompanyDetailsContextObjController,
  kycContextObjController,
  panValidateContextObjController,
  profileContextObjController,
  verifyOTPContextObjController,
} from "../../Common/Constants";
import {
  controllerAPI,
  controllerAPIForFileUpload,
} from "../../API/FP/Actions";
import { display, flexbox, fontWeight, width } from "@mui/system";
import VERFIED from "../../images/VERIFIED.png";

function ProfileKYC({
  profileDetailsApiData,
  firmMemberDetailsList,
  allTypesOfFirmsData,
  isLoading,
  setIsLoading,
}) {
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [kycDeclaration, setKycDeclaration] = useState(false);
  const [typeOfFirm, setTypeOfFirm] = useState("Partnership");

  const [gstNumber, setGstNumber] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [panNumber, setPanNumber] = useState("");
  const [businessPanNumberOTP, setBusinessPanNumberOTP] = useState("");
  const [businessPanNumberOTPSent, setBusinessPanNumberOTPSent] = useState(0);
  const [personalpan, setpersonalpan] = useState(false);
  const [aadharpopupsubmit, setaadharpopupsubmit] = useState(false);
  const [businessPanNumberStatus, setBusinessPanNumberStatus] = useState(0); // 0 default, 1 valid, 2 pending, 3 failed
  const [isChanged, setIsChanged] = useState(false);
  const [isAADHARChanged, setIsAADHARChanged] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [editIndex, setEditIndex] = useState(null);
  const [editData, setEditData] = useState([]);
  const [personAccountNumber, setPersonAccountNumber] = useState("");
  const [personIfscCode, setPersonIfscCode] = useState("");
  const [personBankName, setPersonBankName] = useState("");
  const [personBranchName, setPersonBranchName] = useState("");
  const [popupbtnopen, setpopupbtnopen] = useState(false);
  const [editpopupbtnopen, seteditpopupbtnopen] = useState(false);
  const [
    gstRegistrationCertificateUploadStatus,
    setGstRegistrationCertificateUploadStatus,
  ] = useState(false);
  const [
    udyamRegistrationCertificateUploadStatus,
    setUdyamRegistrationCertificateUploadStatus,
  ] = useState(false);

  const [
    gstRegistrationCertificateVerificationStatus,
    setGstRegistrationCertificateVerificationStatus,
  ] = useState(false);
  const [
    udyamRegistrationCertificateVerificationStatus,
    setUdyamRegistrationCertificateVerificationStatus,
  ] = useState(false);

  const [
    gstRegistrationCertificateFileName,
    setGstRegistrationCertificateFileName,
  ] = useState("");
  const [
    udyamRegistrationCertificateFileName,
    setUdyamRegistrationCertificateFileName,
  ] = useState("");

  const [partnershipDeedFileName, setPartnershipDeedFileName] = useState("");
  const [
    partnershipRegistrationCertificateFileName,
    setPartnershipRegistrationCertificateFileName,
  ] = useState("");

  const [addpartner, setaddedpartner] = useState(0);
  const [panData, setpanData] = useState("");

  function theTypeOfFirm(type) {
    const lowerCaseType = type.toLowerCase();

    if (
      lowerCaseType.includes("limited") &&
      lowerCaseType.includes("liability") &&
      lowerCaseType.includes("partnership")
    ) {
      return "LLP";
    } else if (
      lowerCaseType.includes("limited") &&
      lowerCaseType.includes("company")
    ) {
      return "LIMITED_COMPANY";
    } else if (lowerCaseType.includes("partnership")) {
      return "PARTNERSHIP";
    } else if (lowerCaseType.includes("proprietorship")) {
      return "PROPRIETORSHIP";
    } else if (lowerCaseType.includes("llp")) {
      return "LLP";
    } else {
      return "UNKNOWN_TYPE";
    }
  }
  let value = "";
  if (typeOfFirm === "LIMITED_COMPANY") {
    value = "Limited Company";
  } else if (typeOfFirm === "PARTNERSHIP") {
    value = "Partnership";
  } else if (typeOfFirm === "PROPRIETORSHIP") {
    value = "Proprietorship";
  } else if (typeOfFirm === "LLP") {
    value = "Llp";
  }

  console.log("Value is :", value);
  const [
    certificateOfIncorporationFileName,
    setCertificateOfIncorporationFileName,
  ] = useState("");
  const [llpAgreementFileName, setLlpAgreementFileName] = useState("");

  const [memorandumOfAssociationFileName, setMemorandumOfAssociationFileName] =
    useState("");
  const [articleOfAssociationFileName, setArticleOfAssociationFileName] =
    useState("");

  const [auth, setAuth] = useAuth();
  const [selectedFileGST, setSelectedFileGST] = useState(null);
  const [selectedFileUdyam, setSelectedFileUdyam] = useState(null);

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );
  const [allAadharResponses, setAllAadharResponses] = useState([]);
  const [businesspanarray, setbusinesspanarray] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [isgstError, setgstIsError] = useState(false);
  const [noOfPartners, setNoOfPartnerss] = useState([]);
  const [partnertabledetails, setpartnertabledetails] = useState([]);

  const [noOfPartnerss, setNoOfPartners] = useState([]);
  const [isError, setIsError] = useState([]);
  const [gstapires, setgstapires] = useState("");
  const [otpconformed, setotpverified] = useState(false);
  const [gstnumbervalid, setgstnumbervalid] = useState(false);
  const [isEditing, setIsEditing] = useState(null);
  const [cancelledPartnerIndex, setCancelledPartnerIndex] = useState(null);
  const [bpanchanged, setbpanchange] = useState(false);
  const [companynamechange, setCompanyNamechange] = useState(false);

  // console.log("11111 isErrorisError isError, ", isError, noOfPartners);
  console.log("11111 noOfPartners, ", noOfPartners);
  useEffect(() => {
    if (cancelledPartnerIndex !== null) {
      // Step 3: Effect logic when a partner is cancelled
      console.log(`Partner at index ${cancelledPartnerIndex} was cancelled`);
      // Perform additional actions here

      // Reset the cancelledPartnerIndex after handling the effect
      setCancelledPartnerIndex(null);
    }
    setGstNumber("36AAIFP3688H1ZS");

    // setPersonMobileNumber(profileDetailsApiData?.phoneNumber);
    setCompanyEmail("info@primetextiles.in");
    setTypeOfFirm("PARTNERSHIP");
    setPanNumber("AAIFP3688H");
    setCompanyName("Prime Textiles");
    setPersonAccountNumber("10441140232320");
    setPersonIfscCode("HDFC0002096");
    setPersonBankName("HDFC");
    setPersonBranchName("Warangal");
    setpartnertabledetails([
      {
        aadharName: "PREMA LATHA",
        pan: "AKLPG9156E",
        aadhar: "944401986934",
        aadharAddress:
          "32-2-96/1, Lurdhu Nagar, Hanamkonda Mandal, Warangal - 506015",
      },
      {
        aadharName: "yellepeddi sai balaji srikar",
        pan: "NDVPS1876R",
        aadhar: "460810003133",
        aadharAddress:
          "301 neeladries recidency , DwarakaNagar, Visakhapatnam - 500016",
      },
    ]);
  }, [
    profileDetailsApiData,
    firmMemberDetailsList,
    allTypesOfFirmsData,
    cancelledPartnerIndex,
  ]);
  const openpopup = () => {
    setpopupbtnopen(true);
  };
  const closepopup = (idx) => {
    setaddedpartner(0);
    const temp = [...noOfPartners];
    temp.splice(idx, 1);
    setNoOfPartnerss(temp);
    setpersonalpan(false);
    setaadharpopupsubmit(false);
    setpopupbtnopen(false);
  };

  const openeditpopup = () => {
    seteditpopupbtnopen(true);
  };
  const closeeditpopup = (idx) => {
    setIsEditing(null);
    const temp = [...noOfPartners];
    const retemp = [...noOfPartners];
    temp.splice(idx, 1);
    setNoOfPartnerss(temp);
    setpersonalpan(false);
    setaadharpopupsubmit(false);
    setCancelledPartnerIndex(idx);
    seteditpopupbtnopen(false);
  };

  const handleGSTChange = (e) => {
    const inputValue = e.target.value;
    setGstNumber(inputValue);
    setgstnumbervalid(false);
    setgstIsError(inputValue.length < 15);
    if (inputValue.length === 15) {
      const panSubstring = inputValue.slice(2, 12);
      setPanNumber(panSubstring);
      // console.log("the pan substring is :", panSubstring);
    } else {
      setPanNumber("");
    }
  };

  useEffect(() => {
    setIsError(
      noOfPartners?.filter(
        (x) =>
          x.pan === "" ||
          x.panStatus === "" ||
          x.name_as_per_pan === "" ||
          x.date_of_birth === "" ||
          x.aadhar === "" ||
          x.aadharOtp === "" ||
          x.aadharStatus === "" ||
          x.aadharName === "" ||
          x.aadharAddress === ""
      )
    );
  }, [noOfPartners]);
  const handleChange = (idx, type) => (e) => {
    let name;
    let value;
    if (type !== "date") {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "date_of_birth";
      value = e;
    }
    const temp = [...noOfPartners];
    temp[idx] = { ...noOfPartners[idx], [name]: value };
    setNoOfPartnerss(temp);
    setIsChanged(true);
  };
  const handleAADHARChange = (idx, type) => (e) => {
    let name;
    let value;
    if (type !== "date") {
      name = e.target.name;
      value = e.target.value;
    } else {
      name = "date_of_birth";
      value = e;
    }
    const temp = [...noOfPartners];
    temp[idx] = { ...noOfPartners[idx], [name]: value };
    setNoOfPartnerss(temp);
    setIsAADHARChanged(true);
  };
  const handleAddRowMissing = () => {
    setaddedpartner(1);
    const item = {
      designation: "PARTNER",
      firstName: "",
      lastName: "",
      firmMemberUlidId: "",
      pan: "",
      panStatus: "",
      name_as_per_pan: "",
      date_of_birth: null,
      aadhar: "",
      aadharOtp: "",
      aadharStatus: "",
      aadharName: "",
      aadharAddress: "",
      anchortrader: "",
      newRecord: true,
      oldRecord: false,
    };

    setNoOfPartnerss([...noOfPartners, item]);
    console.log("2222222222222222222222222222222222222");
  };

  /*const handleEditRow = (index) => {
    const item = noOfPartnerss[index];
    setEditIndex(index);
    setEditData(item);
    setNoOfPartnerss([...noOfPartners, item]);
    console.log(
      "2222222222222222222222222222222222222",

      item
    );
  };*/

  const handleEditClick = (index) => {
    setIsEditing(index);
    setEditIndex(index);
    const item = noOfPartnerss[index];
    setNoOfPartnerss([item]);
    const newarray = [...noOfPartnerss];
    newarray[index] = noOfPartners;
    setNoOfPartners(newarray);
    console.log(
      "2222222222222222222222222222222222222  noOfPartnerss[index]",
      noOfPartnerss
    );
  };
  const handleRemoveSpecificRow = (idx) => () => {
    setaddedpartner(0);
    const temp = [...noOfPartners];
    temp.splice(idx, 1);
    setNoOfPartnerss(temp);
    setpopupbtnopen(false);
  };

  const handleCancelsepecificRow = (idx) => () => {
    setIsEditing(null);
    const temp = [...noOfPartners];
    const retemp = [...noOfPartners];
    temp.splice(idx, 1);
    setNoOfPartnerss(temp);
    setCancelledPartnerIndex(idx);
    seteditpopupbtnopen(false);
  };
  const handleChang = (type) => (e) => {
    let name;
    let value;
    setbpanchange(false);
    if (type === "date") {
      name = "date_of_birth";
      value = e;
    } else if (type === "pan") {
      name = e.target.name;
      value = e.target.value;
      setPanNumber(e.target.value);
    } else {
      name = e.target.name;
      value = e.target.value;
      setCompanyNamechange(true);
    }
    setbusinesspanarray((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    console.log("Pan array is:", {
      ...businesspanarray,
      [name]: value,
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  console.log("typeOfFirm", typeOfFirm);
  console.log("hey all :", theTypeOfFirm(gstapires));
  const handleBpanValidation = async (e) => {
    setBusinessPanNumberStatus(2);
    setBusinessPanNumberOTPSent(1);
  };
  const handleBotpValidation = async (e) => {
    setBusinessPanNumberStatus(1);
    setBusinessPanNumberOTPSent(0);
  };
  const [universalclick, setuniversalclick] = useState(false);
  const editbtnclick = () => {
    setuniversalclick(true);
  };

  const cancelclick = () => {
    setuniversalclick(false);
  };
  return (
    <div
      className="body-text"
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <form className="form" style={{ width: "1200px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ color: "#000", fontWeight: "bold" }}>
            This KYC process is a one-time process to comply with RBI
            guidelines. Please follow these steps to complete the KYC process.
          </div>
          <div>
            <div
              style={{
                color: "#000",
                fontWeight: "bold",
                marginTop: "50px",
              }}
            >
              Business Verification :
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}
            >
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: "12px",
                }}
              >
                1) GST Verification:
              </p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "0",
                    position: "relative",
                  }}
                >
                  <TextField
                    inputProps={{ style: { fontSize: 14 }, maxLength: 15 }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    style={{
                      width: "250px",
                    }}
                    id="standard-basic"
                    label="GST Number"
                    value={gstNumber}
                    onChange={handleGSTChange}
                    variant="outlined"
                    required
                    error={isgstError}
                    helperText={
                      isgstError ? "GST Number must be 15 digits long" : ""
                    }
                    disabled={universalclick !== true}
                  />
                  {gstnumbervalid === true ? (
                    <div
                      style={{
                        position: "absolute",
                        right: "25px",
                        top: "100%",
                        transform: "translateY(-50%)",
                        width: "70px",
                        height: "90px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={VERFIED}
                        alt="Verified"
                        style={{
                          width: "200%",
                          height: "200%",
                          objectFit: "cover",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                  ) : (
                    <button
                      type="button"
                      style={{
                        position: "absolute",
                        right: "5px",
                        top: "5px",
                        borderRadius: "5px",
                        background: "rgb(74, 58, 255)",
                        color: "white",
                        border: "none",
                        padding: "5px 15px",
                        cursor: "pointer",
                        height: "45px",
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        opacity:
                          gstNumber?.length < 15 || universalclick !== true
                            ? 0.5
                            : 1,
                      }}
                      disabled={
                        gstNumber?.length < 15 || universalclick !== true
                      }
                    >
                      Validate
                    </button>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <TextField
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    style={{
                      width: "250px",
                    }}
                    id="standard-basic"
                    label="Type of firm"
                    value={value}
                    variant="outlined"
                    readOnly
                    disabled
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    style={{
                      width: "250px",
                    }}
                    id="standard-basic"
                    label="Email address"
                    value={companyEmail}
                    onChange={(e) => setCompanyEmail(e.target.value)}
                    variant="outlined"
                    required
                    disabled={universalclick !== true}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    style={{
                      width: "250px",
                    }}
                    id="standard-basic"
                    label="Name"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    variant="outlined"
                    required
                    disabled={universalclick !== true}
                  />
                </div>
              </div>
              <p
                style={{
                  fontSize: "12px",
                  fontWeight: "bold",
                  margin: "12px",
                }}
              >
                2) PAN Verification:
              </p>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                <div>
                  <TextField
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    style={{
                      width: "250px",
                    }}
                    id="standard-basic"
                    label="Company Full Name"
                    name={"name_as_per_pan"}
                    value={companyName}
                    onChange={handleChang("name")}
                    variant="outlined"
                    required={businessPanNumberOTP === false}
                    disabled={universalclick !== true}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <TextField
                    inputProps={{
                      style: { fontSize: 14 },
                      maxLength: 10,
                    }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    sx={{
                      width: "250px",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor:
                            businessPanNumberOTP === false ? "red" : "",
                        },
                      },
                      "& .MuiInputBase-input::placeholder": {
                        color: "red",
                        opacity: 1,
                      },
                    }}
                    id="standard-basic"
                    label="PAN"
                    placeholder="Enter PAN Number"
                    value={panNumber}
                    name={"pan"}
                    onChange={handleChang("pan")}
                    variant="outlined"
                    required
                    disabled={universalclick !== true}
                  />

                  <div
                    style={{
                      position: "absolute",
                      right: "20px",
                      top: "100%",
                      transform: "translateY(-50%)",
                      width: "100px",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={VERFIED}
                      alt="Verified"
                      style={{
                        width: "200%",
                        height: "200%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    position: "relative",
                  }}
                >
                  <TextField
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{
                      sx: {
                        color: "#4A3AFF",
                        fontWeight: "bold",
                        [`&.${inputLabelClasses.shrink}`]: {
                          color: "#4A3AFF",
                        },
                      },
                    }}
                    style={{
                      width: "250px",
                    }}
                    id="standard-basic"
                    label="PAN Status"
                    value={businessPanNumberOTP || true}
                    variant="outlined"
                    placeholder="PAN Status"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "#000",
              fontWeight: "bold",
              marginTop: "20px",
            }}
          >
            Account details:
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "0",
                  position: "relative",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="standard-basic"
                  label="Account number"
                  value={personAccountNumber}
                  onChange={(e) => setPersonAccountNumber(e.target.value)}
                  variant="outlined"
                  type="number"
                  style={{
                    width: "250px",
                  }}
                  required
                  disabled={universalclick !== true}
                />
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "0",
                  position: "relative",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="standard-basic"
                  label="IFSC code"
                  value={personIfscCode}
                  onChange={(e) =>
                    setPersonIfscCode(e.target.value.replace(/\s/g, ""))
                  }
                  variant="outlined"
                  required
                  style={{
                    width: "250px",
                  }}
                  disabled={universalclick !== true}
                />
                <button
                  type="button"
                  style={{
                    position: "absolute",
                    right: "5px",
                    borderRadius: "5px",
                    background: "rgb(74, 58, 255)",
                    color: "white",
                    border: "none",
                    padding: "5px 15px",
                    cursor: "pointer",
                    height: "45px",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    opacity:
                      universalclick !== true || personIfscCode?.length === 0
                        ? 0.5
                        : 1,
                  }}
                  disabled={
                    personIfscCode?.length === 0 || universalclick !== true
                  }
                >
                  Fetch
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="standard-basic"
                  label="Bank name"
                  value={personBankName}
                  onChange={(e) => setPersonBankName(e.target.value)}
                  variant="outlined"
                  required
                  style={{
                    width: "250px",
                  }}
                  disabled={universalclick !== true}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <TextField
                  inputProps={{ style: { fontSize: 14 } }}
                  InputLabelProps={{
                    sx: {
                      color: "#4A3AFF",
                      fontWeight: "bold",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "#4A3AFF",
                      },
                    },
                  }}
                  id="standard-basic"
                  label="Branch name"
                  value={personBranchName}
                  onChange={(e) => setPersonBranchName(e.target.value)}
                  variant="outlined"
                  required
                  style={{
                    width: "250px",
                  }}
                  disabled={universalclick !== true}
                />
              </div>
            </div>
          </div>

          <div>
            {typeOfFirm && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "50px",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <label
                  style={{
                    width: "250px",
                    color: "#4A3AFF",
                    fontWeight: "bold",
                  }}
                >
                  {typeOfFirm == "PROPRIETORSHIP"
                    ? "GST registration certificate *"
                    : typeOfFirm == "PARTNERSHIP"
                    ? "Partnership Deed *"
                    : typeOfFirm == "LLP"
                    ? "Certificate of Incorporation *"
                    : typeOfFirm == "LIMITED_COMPANY"
                    ? "Memorandum of Association *"
                    : ""}
                </label>
                <input
                  type="file"
                  id="gst-file-upload"
                  accept="application/pdf"
                  required={
                    gstRegistrationCertificateUploadStatus ? false : true
                  }
                  //onChange={(e) => changeHandlerForUploadGST(e)}
                  style={{
                    opacity: universalclick !== true ? "0.5" : "1",
                  }}
                  disabled
                />

                <span style={{ color: "#3F4F5A" }}>
                  {gstRegistrationCertificateUploadStatus &&
                    !gstRegistrationCertificateVerificationStatus &&
                    "Uploaded"}
                </span>
                <span style={{ color: "#00692A" }}>
                  {gstRegistrationCertificateUploadStatus &&
                    gstRegistrationCertificateVerificationStatus &&
                    "Verified"}
                </span>
                <br />
              </div>
            )}
          </div>
          <div>
            {typeOfFirm && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "2%",
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <label
                  style={{
                    width: "250px",
                    color: "#4A3AFF",
                    fontWeight: "bold",
                  }}
                >
                  {typeOfFirm == "PROPRIETORSHIP"
                    ? "Udyam Registration Certificate *"
                    : typeOfFirm == "PARTNERSHIP"
                    ? "Partnership Registration Certificate*"
                    : typeOfFirm == "LLP"
                    ? "LLP Agreement *"
                    : typeOfFirm == "LIMITED_COMPANY"
                    ? "Article of Association *"
                    : ""}
                </label>
                <input
                  type="file"
                  id="file-upload"
                  accept="application/pdf"
                  required={
                    udyamRegistrationCertificateUploadStatus ? false : true
                  }
                  style={{
                    opacity: universalclick !== true ? "0.5" : "1",
                  }}
                  disabled
                />
                <span style={{ color: "#3F4F5A" }}>
                  {udyamRegistrationCertificateUploadStatus &&
                    !udyamRegistrationCertificateVerificationStatus &&
                    "Uploaded"}
                </span>
                <span style={{ color: "#00692A" }}>
                  {udyamRegistrationCertificateUploadStatus &&
                    udyamRegistrationCertificateVerificationStatus &&
                    "Verified"}
                </span>
              </div>
            )}
          </div>

          {partnertabledetails?.length > 0 ? (
            <>
              <h1>
                {typeOfFirm == "PROPRIETORSHIP"
                  ? " Individual "
                  : typeOfFirm == "PARTNERSHIP"
                  ? " Partner "
                  : typeOfFirm == "LLP"
                  ? " Director "
                  : typeOfFirm == "LIMITED_COMPANY"
                  ? " Director "
                  : " Person"}{" "}
                Details
              </h1>
              <div
                style={{
                  width: "50%",
                  color: "#000",
                  fontWeight: "bold",
                  display: "flex",
                  margin: "15px 0px",
                }}
              >
                Personal Verification :{" "}
                {typeOfFirm == "Proprietorship" && noOfPartners?.length >= 1 ? (
                  <></>
                ) : (
                  <>
                    <button
                      id="add-person"
                      type="button"
                      style={{
                        marginLeft: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "5px 15px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        opacity:
                          isEditing !== null ||
                          addpartner !== 0 ||
                          universalclick !== true
                            ? "0.5"
                            : "1",
                        // opacity: "0.5",
                      }}
                      onClick={() => {
                        handleAddRowMissing();
                        openpopup();
                      }}
                      disabled={
                        isEditing !== null ||
                        addpartner !== 0 ||
                        universalclick !== true
                      }
                    >
                      Add
                      {typeOfFirm == "PROPRIETORSHIP"
                        ? " Individual "
                        : typeOfFirm == "PARTNERSHIP"
                        ? " Partner "
                        : typeOfFirm == "LLP"
                        ? " Director "
                        : typeOfFirm == "LIMITED_COMPANY"
                        ? " Director "
                        : " Person"}
                    </button>
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <table
                  style={{
                    width: "100%",
                    margin: "1px",
                    borderCollapse: "collapse",
                    padding: "10px",
                  }}
                >
                  <thead>
                    <tr>
                      <th
                        style={{
                          width: "5%",
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        S.No.
                      </th>
                      <th
                        style={{
                          width: "20%",
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Name as per Aadhaar
                      </th>
                      <th
                        style={{
                          width: "15%",
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        PAN
                      </th>
                      <th
                        style={{
                          width: "15%",
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Designation
                      </th>
                      <th
                        style={{
                          width: "15%",
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Aadhaar
                      </th>
                      <th
                        style={{
                          width: "20%",
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        Address
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {partnertabledetails?.map((item, index) => (
                      <tr key={index} style={{ border: "1px solid black" }}>
                        <td style={{ padding: "8px" }}>{index + 1}</td>
                        <td style={{ padding: "8px" }}>{item.aadharName}</td>
                        <td style={{ padding: "8px" }}>{item?.pan}</td>
                        <td style={{ padding: "8px" }}>
                          {typeOfFirm == "PROPRIETORSHIP"
                            ? " Individual "
                            : typeOfFirm == "PARTNERSHIP"
                            ? " Partner "
                            : typeOfFirm == "LLP"
                            ? " Director "
                            : typeOfFirm == "LIMITED_COMPANY"
                            ? " Director "
                            : " Person"}{" "}
                          {index + 1}
                        </td>
                        <td style={{ padding: "8px" }}>{item.aadhar}</td>
                        <td
                          style={{
                            padding: "8px",
                            maxWidth: "200px",
                            maxHeight: "40px",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              maxHeight: "100px",
                              overflowY: "auto",
                            }}
                          >
                            {item.aadharAddress}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  width: "50%",
                  color: "#000",
                  fontWeight: "bold",
                  display: "flex",
                  margin: "15px 0px",
                }}
              >
                Personal Verification :{" "}
                {typeOfFirm == "Proprietorship" && noOfPartners?.length >= 1 ? (
                  <></>
                ) : (
                  <>
                    <button
                      id="add-person"
                      type="button"
                      style={{
                        marginLeft: "10px",
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "5px 15px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        opacity:
                          isEditing !== null ||
                          addpartner !== 0 ||
                          universalclick !== true
                            ? "0.5"
                            : "1",
                        // opacity: "0.5",
                      }}
                      onClick={() => {
                        handleAddRowMissing();
                        openpopup();
                      }}
                      disabled={
                        isEditing !== null ||
                        addpartner !== 0 ||
                        universalclick !== true
                      }
                    >
                      Add
                      {typeOfFirm == "PROPRIETORSHIP"
                        ? " Individual "
                        : typeOfFirm == "PARTNERSHIP"
                        ? " Partner "
                        : typeOfFirm == "LLP"
                        ? " Director "
                        : typeOfFirm == "LIMITED_COMPANY"
                        ? " Director "
                        : " Person"}
                    </button>
                  </>
                )}
              </div>
            </>
          )}

          <div style={{ marginTop: "3%" }}></div>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "Column",
              flexWrap: "wrap",
            }}
          >
            <Dialog
              open={popupbtnopen}
              onClose={closepopup}
              maxWidth="md"
              PaperProps={{
                style: { borderRadius: 20, zIndex: "1000" }, // Adding border radius to the dialog
              }}
            >
              <DialogTitle>
                Add a new{" "}
                {typeOfFirm == "PROPRIETORSHIP"
                  ? " Individual "
                  : typeOfFirm == "PARTNERSHIP"
                  ? " Partner "
                  : typeOfFirm == "LLP"
                  ? " Director "
                  : typeOfFirm == "LIMITED_COMPANY"
                  ? " Director "
                  : " Person"}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={closepopup}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <Divider />
              <DialogContent>
                {isLoading && (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      zIndex: "1100",
                    }}
                  />
                )}
                <DialogContentText>
                  <form
                    style={{
                      display: "flex",
                      padding: "20px",
                    }}
                  >
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      {noOfPartners?.map((item, index) => {
                        return (
                          <div
                            style={{
                              margin: "0% 5% 3% 0%",
                            }}
                          >
                            {isEditing != null ? (
                              <>
                                {" "}
                                <div
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    marginBottom: "20px",
                                  }}
                                >
                                  {typeOfFirm == "PROPRIETORSHIP"
                                    ? "Individual "
                                    : typeOfFirm == "PARTNERSHIP"
                                    ? "Partner "
                                    : typeOfFirm == "LLP"
                                    ? "Director "
                                    : typeOfFirm == "LIMITED_COMPANY"
                                    ? "Director "
                                    : ""}
                                  {/* Partner {index + 1} */}
                                  {<>{editIndex + 1}</>}

                                  <button
                                    id={`remove-person-${index}`}
                                    type="button"
                                    style={{
                                      marginLeft: "60px",
                                      borderRadius: "5px",
                                      backgroundColor: "#4A3AFF",
                                      color: "white",
                                      border: "none",
                                      padding: "5px 15px",
                                      cursor: "pointer",
                                      height: "fit-content",
                                      boxShadow:
                                        "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                      // opacity: checkBoxValue === true ? "1" : "0.5",
                                      // opacity: "0.5",
                                    }}
                                    onClick={handleCancelsepecificRow(index)}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    {typeOfFirm == "PROPRIETORSHIP"
                                      ? "Individual "
                                      : typeOfFirm == "PARTNERSHIP"
                                      ? "Partner "
                                      : typeOfFirm == "LLP"
                                      ? "Director "
                                      : typeOfFirm == "LIMITED_COMPANY"
                                      ? "Director "
                                      : ""}
                                    {/* Partner {index + 1} */}
                                  </div>
                                  <div
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    {typeOfFirm != "Proprietorship" && (
                                      <>
                                        <button
                                          id={`remove-person-${index}`}
                                          type="button"
                                          style={{
                                            marginLeft: "60px",
                                            borderRadius: "5px",
                                            backgroundColor: "#4A3AFF",
                                            color: "white",
                                            border: "none",
                                            padding: "5px 15px",
                                            cursor: "pointer",
                                            height: "fit-content",
                                            boxShadow:
                                              "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                                            // opacity: checkBoxValue === true ? "1" : "0.5",
                                            // opacity: "0.5",
                                          }}
                                          onClick={handleRemoveSpecificRow(
                                            index
                                          )}
                                        >
                                          Remove
                                        </button>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: "100px",
                                margin: "10px",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>PAN validation</p>
                                <div>
                                  <TextField
                                    inputProps={{
                                      style: { fontSize: 14 },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: "#4A3AFF",
                                        fontWeight: "bold",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                          color: "#4A3AFF",
                                        },
                                      },
                                    }}
                                    style={{
                                      width: "250px",
                                    }}
                                    id="standard-basic"
                                    label="Name as per PAN"
                                    name={"name_as_per_pan"}
                                    value={noOfPartners[index]?.name_as_per_pan}
                                    onChange={handleChange(index)}
                                    variant="outlined"
                                    required
                                  />
                                </div>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    width: "250px",
                                  }}
                                ></div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "20px",
                                  }}
                                >
                                  <div
                                    type="btn"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "0",
                                      position: "relative",
                                    }}
                                  >
                                    <TextField
                                      inputProps={{
                                        style: { fontSize: 14 },
                                      }}
                                      InputLabelProps={{
                                        sx: {
                                          color: "#4A3AFF",
                                          fontWeight: "bold",
                                          [`&.${inputLabelClasses.shrink}`]: {
                                            color: "#4A3AFF",
                                          },
                                        },
                                      }}
                                      style={{
                                        width: "250px",
                                      }}
                                      id="standard-basic"
                                      label="PAN"
                                      name={"pan"}
                                      value={noOfPartners[index]?.pan}
                                      onChange={handleChange(index)}
                                      variant="outlined"
                                    />
                                    {panData.status === "valid" &&
                                    panData?.name_as_per_pan_match === true &&
                                    panData?.date_of_birth_match === true &&
                                    !noOfPartners[index].newRecord &&
                                    !isChanged ? (
                                      <div
                                        style={{
                                          position: "absolute",
                                          right: "20px",
                                          top: "100%",
                                          transform: "translateY(-50%)",
                                          width: "100px",
                                          height: "100px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <img
                                          src={VERFIED}
                                          alt="Verified"
                                          style={{
                                            width: "200%",
                                            height: "200%",
                                            objectFit: "cover",
                                            borderRadius: "50%",
                                          }}
                                        />
                                      </div>
                                    ) : (
                                      <button
                                        type="button"
                                        style={{
                                          position: "absolute",
                                          right: "5px",
                                          top: "5px",
                                          borderRadius: "5px",
                                          background: "rgb(74, 58, 255)",
                                          color: "white",
                                          border: "none",
                                          padding: "5px 15px",
                                          cursor: "pointer",
                                          height: "45px",
                                          boxShadow:
                                            "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",

                                          opacity:
                                            noOfPartners[index].pan?.length ===
                                            10
                                              ? "1"
                                              : "0.5",
                                        }}
                                        disabled={
                                          noOfPartners[index].pan?.length === 10
                                            ? false
                                            : true
                                        }
                                      >
                                        Validate
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <div style={{ marginTop: "20px" }}>
                                  <TextField
                                    inputProps={{
                                      style: { fontSize: 14 },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: "#4A3AFF",
                                        fontWeight: "bold",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                          color: "#4A3AFF",
                                        },
                                      },
                                    }}
                                    style={{
                                      width: "250px",
                                    }}
                                    id="standard-basic"
                                    label="PAN Status"
                                    name={"panStatus"}
                                    value={noOfPartners[index].panStatus}
                                    onChange={handleChange(index)}
                                    variant="outlined"
                                    disabled
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <p>Aadhaar Validation</p>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "0",
                                    position: "relative",
                                  }}
                                >
                                  <TextField
                                    inputProps={{
                                      style: { fontSize: 14 },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: "#4A3AFF",
                                        fontWeight: "bold",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                          color: "#4A3AFF",
                                        },
                                      },
                                    }}
                                    style={{
                                      width: "250px",
                                    }}
                                    id="standard-basic"
                                    label="Aadhaar"
                                    name={"aadhar"}
                                    value={noOfPartners[index].aadhar}
                                    onChange={handleAADHARChange(index)}
                                    variant="outlined"
                                  />
                                  <button
                                    type="button"
                                    style={{
                                      position: "absolute",
                                      right: "5px",
                                      top: "5px",
                                      borderRadius: "5px",
                                      background: "rgb(74, 58, 255)",
                                      color: "white",
                                      border: "none",
                                      padding: "5px 15px",
                                      cursor: "pointer",
                                      height: "45px",
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      opacity:
                                        noOfPartners[index].aadhar?.length ===
                                          12 && aadharpopupsubmit === false
                                          ? "1"
                                          : "0.5",
                                    }}
                                    disabled={
                                      !(
                                        noOfPartners[index].aadhar?.length ===
                                        12
                                      ) || aadharpopupsubmit === true
                                    }
                                  >
                                    Generate OTP
                                  </button>
                                </div>
                                <div
                                  style={{
                                    marginTop: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    marginLeft: "0",
                                    position: "relative",
                                  }}
                                >
                                  <TextField
                                    inputProps={{
                                      style: { fontSize: 14 },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: "#4A3AFF",
                                        fontWeight: "bold",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                          color: "#4A3AFF",
                                        },
                                      },
                                    }}
                                    style={{
                                      width: "250px",
                                    }}
                                    id="standard-basic"
                                    label="OTP"
                                    required
                                    name={"aadharOtp"}
                                    value={noOfPartners[index].aadharOtp}
                                    onChange={handleAADHARChange(index)}
                                    variant="outlined"
                                  />
                                  {otpconformed === true &&
                                  !noOfPartners[index].newRecord &&
                                  isAADHARChanged !== true ? (
                                    <div
                                      style={{
                                        position: "absolute",
                                        right: "20px",
                                        top: "100%",
                                        transform: "translateY(-50%)",
                                        width: "100px",
                                        height: "100px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={VERFIED}
                                        alt="Verified"
                                        style={{
                                          width: "200%",
                                          height: "200%",
                                          objectFit: "cover",
                                          borderRadius: "50%",
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <button
                                      type="button"
                                      style={{
                                        position: "absolute",
                                        right: "5px",
                                        top: "5px",
                                        borderRadius: "5px",
                                        background: "rgb(74, 58, 255)",
                                        color: "white",
                                        border: "none",
                                        padding: "5px 15px",
                                        cursor: "pointer",
                                        height: "45px",
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.1) 0px 3px 5px 0px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        marginLeft: "10px",
                                        backgroundColor: "#4A3AFF",
                                        opacity:
                                          noOfPartners[index].aadharOtp
                                            ?.length >= 6
                                            ? "1"
                                            : "0.5",
                                      }}
                                      disabled={
                                        noOfPartners[index].aadharOtp?.length <
                                        6
                                      }
                                    >
                                      Validate
                                    </button>
                                  )}
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                  <TextField
                                    inputProps={{
                                      style: { fontSize: 14 },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: "#4A3AFF",
                                        fontWeight: "bold",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                          color: "#4A3AFF",
                                        },
                                      },
                                    }}
                                    style={{
                                      width: "250px",
                                    }}
                                    id="standard-basic"
                                    label="Status"
                                    name={"aadharStatus"}
                                    value={noOfPartners[index].aadharStatus}
                                    variant="outlined"
                                    disabled
                                  />
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                  <TextField
                                    inputProps={{
                                      style: { fontSize: 14 },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: "#4A3AFF",
                                        fontWeight: "bold",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                          color: "#4A3AFF",
                                        },
                                      },
                                    }}
                                    style={{
                                      width: "250px",
                                    }}
                                    id="standard-basic"
                                    label="Name"
                                    name={"aadharName"}
                                    value={noOfPartners[index].aadharName}
                                    variant="outlined"
                                    disabled
                                  />
                                </div>
                                <div style={{ marginTop: "20px" }}>
                                  <TextField
                                    inputProps={{
                                      style: { fontSize: 14 },
                                    }}
                                    InputLabelProps={{
                                      sx: {
                                        color: "#4A3AFF",
                                        fontWeight: "bold",
                                        [`&.${inputLabelClasses.shrink}`]: {
                                          color: "#4A3AFF",
                                        },
                                      },
                                    }}
                                    style={{
                                      width: "250px",
                                    }}
                                    id="standard-basic"
                                    label="Address"
                                    name={"aadharAddress"}
                                    value={noOfPartners[index].aadharAddress}
                                    variant="outlined"
                                    disabled
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <button
                      type="button"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#4A3AFF",
                        color: "white",
                        border: "none",
                        padding: "8px 8px",
                        cursor: "pointer",
                        height: "fit-content",
                        boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                        margin: "5px",
                        width: "100px",
                        position: "absolute",
                        right: 8,
                        bottom: 8,
                      }}
                    >
                      Save
                    </button>
                  </form>
                </DialogContentText>
              </DialogContent>
              <DialogActions></DialogActions>
            </Dialog>
          </div>
          <h4
            style={{
              marginBottom: "5px",
              marginTop: "60px",
              color: "#4A3AFF",
            }}
          >
            {" "}
            <Checkbox
              {...label}
              checked={kycDeclaration}
              onChange={() => setKycDeclaration(!kycDeclaration)}
              required
            />
            I hereby declare that the above information is provided by me to the
            best of my knowledge and beliefs.
          </h4>
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <button
              type="button"
              id="total-submit"
              style={{
                //   marginLeft: "10px",
                borderRadius: "5px",
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "5px 15px",
                cursor: "pointer",
                borderRadius: "5px",
                height: "fit-content",
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                opacity:
                  isError?.length === 0 &&
                  typeOfFirm !== null &&
                  universalclick === true
                    ? "1"
                    : "0.5",
              }}
              disabled={
                isError.length !== 0 ||
                typeOfFirm === null ||
                universalclick !== true
              }
              // onClick={() => saveProfileDetails()}
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <button
          style={{
            borderRadius: "5px",
            backgroundColor: "#4A3AFF",
            color: "white",
            border: "none",
            padding: "8px 8px",
            cursor: "pointer",
            height: "fit-content",
            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            opacity: universalclick ? "0.5" : "1",
            width: "90px",
            marginRight: universalclick ? "10px" : "0",
          }}
          onClick={editbtnclick}
        >
          Edit Details
        </button>

        {universalclick && (
          <button
            style={{
              borderRadius: "5px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              padding: "8px 8px",
              cursor: "pointer",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
            }}
            onClick={cancelclick}
          >
            Cancel
          </button>
        )}
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleClose}
          severity={alertSeverity}
          sx={{ width: "100%" }}
        >
          {apiResultMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ProfileKYC;
