export const requestStates = (value) => {
  if (value.toString() === "AWAITING_ACCEPTANCE") {
    return "Awaiting Acceptance";
  } else if (value.toString() === "AWAITING_REPAYMENT") {
    return "Awaiting Repayment";
  } else if (value.toString() === "AWAITING_DISBURSEMENT") {
    return "Awaiting Disbursement";
  } else if (value.toString() === "REQUEST_CLOSED") {
    return "Request Closed";
  } else if (value.toString() === "REQUEST_CLOSED") {
    return "Request Closed";
  } else if (value.toString() === "REQUEST_REJECTED") {
    return "Request Rejected";
  } else if (value.toString() === "REQUEST_EXPIRED") {
    return "Request Expired";
  } else if (value.toString() === "AWAITING_OFFER") {
    return "Awaiting Offer";
  } else if (value.toString() === "PENDING_REPAYMENT") {
    return "Pending Repayment";
  } else if (value.toString() === "offer_recived") {
    return "Offer Recived ";
  } else if (value.toString() === "Pending_settllement") {
    return "Pending  Settllement  ";
  } else if (value.toString() === "Req_Closed") {
    return "Request Closed";
  }
};

export const dateFormat = (date, days) => {
  date.setDate(date.getDate() + days);
  let month = date.toLocaleString("default", { month: "short" }); //months from 1-12
  let day = date.getUTCDate();
  let year = date.getUTCFullYear();
  return month + " " + day + ", " + year;
};

const actionDescription = (actionCode) => {
  if (actionCode === "fetch") {
    return "Fetching ....";
  } else if (actionCode === "new") {
    return "Creating a new Placed Offer";
  }
};

export const contextObj = (clientId, actionCode, objectName, id) => {
  return {
    context: {
      transactionId: "",
      transactionDate: new Date().toISOString(),
      clientId: clientId,
      cpcode: "",
      action: {
        actionId: "",
        actionCode: actionCode,
        actionDescription: actionDescription(actionCode),
        actionVal: "",
        cpCode: "",
      },
    },
    message: {
      id: "",
      msgDetails: {
        [objectName]: {},
      },
    },
  };
};

export const contextObjForPlacingOffer = (
  clientId,
  actionCode,
  requestOfferObj,
  placedOfferObj
) => {
  return {
    context: {
      transactionId: "",
      transactionDate: new Date().toISOString(),
      clientId: clientId,
      cpcode: "",
      action: {
        actionId: "",
        actionCode: actionCode,
        actionDescription: actionDescription(actionCode),
        actionVal: "",
        cpCode: "",
      },
    },
    message: {
      id: "",
      msgDetails: {
        requestOffer: requestOfferObj,
        placedOffer: placedOfferObj,
      },
    },
  };
};
