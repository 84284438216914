export const OpenRequestsMockData = [
  {
    requestOfferRefNo: "REQ-2023-123456",
    financeRequestDate: "Sep 25, 2024",
    tradePartnerName: "Multiple",
    anchorTraderName: "Prime Textiles",
    creditScore: "40",
    tradeValue: "2,88,725",
    term: "30",
    marginPtg: "20",
    marginValue: "57745",
    amountAftMargin: "230980",
    interestPtg: "18",
    interestValue: "3417",
    netAmount: "227563",
    status: "OPEN_OFFER",
  },
  {
    requestOfferRefNo: "REQ-2023-147894",
    financeRequestDate: "Sep 26, 2024",
    tradePartnerName: "Renova Hospitals",
    anchorTraderName: "Prime Textiles",
    creditScore: "50",
    tradeValue: "6,50,000",
    term: "60",
    marginPtg: "20",
    marginValue: "57745",
    amountAftMargin: "230980",
    interestPtg: "18",
    interestValue: "3417",
    netAmount: "227563",
    status: "OPEN_OFFER",
  },
  {
    requestOfferRefNo: "REQ-2023-852369",
    financeRequestDate: "Sep 27, 2024",
    tradePartnerName: "Acme.co",
    anchorTraderName: "Prime Textiles",
    creditScore: "60",
    tradeValue: "7,00,000",
    term: "90",
    marginPtg: "20",
    marginValue: "57745",
    amountAftMargin: "230980",
    interestPtg: "18",
    interestValue: "3417",
    netAmount: "227563",
    status: "OPEN_OFFER",
  },
];

export const invoicesMockData = [
  {
    invoiceDate: "Sep 15, 2024",
    invoiceNumber: "INV569829",
    anchorTraderName: "Prime Textiles",
    tradePartnerName: "Apollo Hospitals",
    tradeAmount: "4,50,000",
    invoiceTerm: 80,
    tradeDocSource: "Tally",
    tradeDocCredibility: "High",
    tradeMilestoneStatus: "Unknown",
    tradeAdvancePayment: "No",
    acceptanceFromTradePartner: "Not Reviewed",
    reasonForFinance: "Buying Raw Materials",
    tradePartnerLocation: "Hyderabad",
    buyerGstId: "36AAACA5443N2ZI",
    tradePartnerSector: "Hospitals",
  },
  {
    invoiceDate: "Sep  17, 2024",
    invoiceNumber: "INV403441",
    tradePartnerName: "Renova Hospitals",
    anchorTraderName: "Prime Textiles",
    tradeAmount: "3,00,000",
    invoiceTerm: 70,
    tradeDocSource: "Tally",
    tradeDocCredibility: "High",
    tradeMilestoneStatus: "Unknown",
    tradeAdvancePayment: "No",
    acceptanceFromTradePartner: "Reviewed",
    reasonForFinance: "Buying Raw Materials",
    tradePartnerLocation: "Vijayawada",
    buyerGstId: "36ABCCS4959C1Z9",
    tradePartnerSector: "Hospitals",
  },
  {
    invoiceDate: "Sep  18, 2024",
    invoiceNumber: "INV457608",
    tradePartnerName: "Renova Hospitals",
    anchorTraderName: "Prime Textiles",
    tradeAmount: "3,50,000",
    invoiceTerm: 70,
    tradeDocSource: "Tally",
    tradeDocCredibility: "High",
    tradeMilestoneStatus: "Unknown",
    tradeAdvancePayment: "No",
    acceptanceFromTradePartner: "Reviewed",
    reasonForFinance: "Buying Raw Materials",
    tradePartnerLocation: "Vijayawada",
    buyerGstId: "36ABCCS4959C1Z9",
    tradePartnerSector: "Hospitals",
  },
];

export const placedOffersMockData = [
  {
    id: 1571,
    reqOffId: "01HK8627PBFM5JR62DRPYBSMYH",
    placedOfferId: "a101",
    placedOfferRefNo: "OFPD-PBY-FRCR-PTS-1516",
    requestOfferRefNo: "101a",
    value: 288725,
    reqAmount: 288725,
    marginPtg: 20,
    marginValue: 57745,
    amountAftMargin: 230980,
    interestPtg: 18,
    term: 60,
    interestValue: 6834,
    netAmount: 224146,
    status: "AWAITING_DISBURSEMENT",
    offerDate: "2024-09-19",
    requestId: "1111",
    placedOfferDate: "2024-09-21",
    anchorTrader: "Prime Textiles",
    tradePartner: "Multiple",
    disbursalAmount: "120000",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    financepartner: {
      id: 1500,
      tenantId: null,
      fpId: "111",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      organization: null,
    },
  },
  {
    id: 1615,
    reqOffId: "01HNQ7QZN0G4DR5G372T9D6N08",
    placedOfferId: "01HNQ7QZN0M7ZM8QSEM5EZFE1R",
    placedOfferRefNo: "OFPD-PBY-FRCR-PTS-1615",
    requestOfferRefNo: "ROCR-PBY-FRCR-PTS-1615",
    value: 288725,
    reqAmount: 288725,
    marginPtg: 20,
    marginValue: 57745,
    amountAftMargin: 230980,
    interestPtg: 18,
    term: 60,
    interestValue: 6834,
    netAmount: 224146,
    status: "AWAITING_ACCEPTANCE",
    offerDate: "2024-09-20",
    requestId: null,
    placedOfferDate: "2024-09-20",
    anchorTrader: "Prime Textiles",
    tradePartner: "Multiple",
    disbursalAmount: "224146",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    financepartner: {
      id: 1500,
      tenantId: null,
      fpId: "111",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      organization: null,
    },
  },
];

export const acceptedOfferMockData = [
  {
    id: 1500,
    offerId: "01HK877F3DYYPZR9REDBZEP510",
    acceptedOfferRefNo: "OFAD-IKF-1500",
    reqOffId: 1507,
    value: 288725,
    reqAmount: 288725,
    marginPtg: 20,
    marginValue: 57745,
    amountAftMargin: 230980,
    interestPtg: 18,
    term: 60,
    interestValue: 6834,
    netAmount: 224146,
    status: "Awaiting Repayment",
    offerDate: "2024-09-19",
    offerAcceptedDate: "2024-09-21",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    financepartner: {
      id: 1500,
      tenantId: null,
      fpId: "111",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      organization: null,
    },
    anchortrader: {
      id: 1501,
      orgId: null,
      tenantId: null,
      atId: "AT5116",
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      anchorTraderName: null,
      location: null,
      anchorTraderGST: null,
      anchorTraderSector: null,
      anchorTraderPAN: null,
      kycCompleted: null,
      bankDetails: null,
      organization: null,
    },
  },
];

export const disbursementMockData = [
  {
    id: 1503,
    dbmtId: "01HK85D1WYMHNHANACD2Z5GD82",
    disbursementRefNo: "DBCR-IKF-1503",
    acceptedOfferId: 1502,
    offerId: 1503,
    offerAcceptedDate: "2024-09-25",
    dbmtRequestId: "4545",
    dbmtReqAmount: 224146,
    currency: "4",
    dbmtRequestDate: "2024-08-25",
    dbmtStatus: "Awaiting Disbursement",
    offerStatus: "Awaiting Disbursement",
    ftTrnxDetailsId: "101",
    collectionTrnxDetailsId: "1003",
    docId: 101,
    dbmtDateTime: "01/10/2023",
    dbmtAmount: 111111111,
    financeRequestId: "01HK84A2X99GNY0BRWXXJQS00M",
    amountToBeDisbursed: "224146",
    destinationAccountName: "IDFC Escrow account",
    destinationAccountNumber: "PBD110000000001",
    ifscCode: "IDFB0080202",
    status: "Awaiting Disbursement",
    actionByDate: "2024-09-25",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
    financepartner: {
      id: 1500,
      tenantId: null,
      fpId: "111",
      orgId: null,
      customerName: null,
      orgName: null,
      gstId: null,
      phoneNumber: null,
      organization: null,
    },
  },
];

export const repaymentMockData = [
  {
    id: 1502,
    repaymentId: "01HK86PYQC3HPMBJBF16Q5YN67",
    repaymentRefNo: "RPCR-IKF-1502",
    acceptedOfferId: 1234,
    offerId: 345,
    offerAcceptedDate: "2024-09-25",
    dbmtRequestId: "345",
    dbmtStatus: "Paid",
    dbmtDateTime: "2024-09-25",
    dbmtId: "345",
    dbmtAmount: 129880,
    currency: "56",
    repaymentStatus: "Paid",
    repaymentDate: "2024-10-25",
    repaymentAmount: 136000,
    ftTrnxDetailsId: "101",
    collectionTrnxDetailsId: "1003",
    docId: 1111,
    financeRequestId: "01HK84A2X99GNY0BRWXXJQS00M",
    repaymentDueDate: "2024-10-25",
    totalRepaymentAmount: "230980",
    amountRepayed: "150000",
    amountToBePaid: "80980",
    sourceAccountName: "IDFC",
    sourceAccountNumber: "75622010002960",
    ifscCode: "PB4532176",
    status: "Paid",
    referenceNumber: "436919760606",
    financerequest: {
      id: 1505,
      requestId: "01HK84A2X99GNY0BRWXXJQS00M",
      financeRequestRefNo: null,
      tradeChannelId: null,
      requestAmount: null,
      requestDate: null,
      currency: null,
      requestStatus: null,
      dueDate: null,
      anchortrader: null,
    },
  },
];
