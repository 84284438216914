import React from "react";
import dasBoardAT from "../images/ATdashboard.png";

const AtDashBoard = ({ setActiveIndex }) => {
  setActiveIndex(4);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "20px",
      }}
    >
      <img
        src={dasBoardAT}
        alt="Dashboard Icon"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      />
    </div>
  );
};

export default AtDashBoard;
