import React, { useCallback, useEffect, useState } from "react";
import { getplacedoffers } from "../API/FP/Actions";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, CircularProgress, Typography } from "@mui/material";
import { controllerAPI, callAPI } from "../API/FP/Actions";
import { dateFormat, requestStates } from "../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import {
  contextObjforatplaced,
  contextObjforapicall,
  contextObj,
} from "../Common/Constants";

function ATTrades({ setActiveIndex }) {
  setActiveIndex(3);
  const navigate = useNavigate();
  const [data, setData] = useState([
    {
      tradeDate: "Sep 21 2024, 11:27",
      tradeNo: "INV-1987",
      targetName: "Apollo Hospitals",
      tradeAmount: 50000,
      tradePartnerConfirmation: "Not approved",
    },
    {
      tradeDate: "Sep 14 2024, 11:27",
      tradeNo: "INV-1987",
      targetName: "Apollo Hospitals",
      tradeAmount: 50000,
      tradePartnerConfirmation: "Not approved",
    },
    {
      tradeDate: "Sep 20 2024, 0:0",
      tradeNo: "INV-1999",
      targetName: "profintech",
      tradeAmount: 1500,
      tradePartnerConfirmation: "Not approved",
    },
  ]);

  // Pagination  variables
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const totalPages = Math.ceil(data.length / pageSize);
  const lastindex = currentPage * pageSize;
  const firstindex = lastindex - pageSize;
  const recordss = data.slice(firstindex, lastindex);
  const numbers = [...Array(totalPages + 1).keys()].slice(1);

  const creditScoreColor = (score) => {
    return score > 7 ? "#00692A" : "#D20319";
  };

  // Function to execute when link is clicked

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const ChangePage = (pageNumber) => () => setCurrentPage(pageNumber);

  return (
    <>
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "50px",
            }}
          >
            <div className="header">
              <h3 style={{ margin: "0px" }} className="section-heading">
                Total Trade Details
              </h3>
            </div>
          </div>
        </div>
      </Finance1>

      <Dash className="body-text">
        <div className="main-content">
          <div className="record-details">
            <table>
              <tbody>
                <tr>
                  <th>Date</th>
                  <th>Invoice Number</th>
                  <th>Trade Partner Name</th>
                  <th>Amount</th>
                  <th>Term</th>
                  <th>Trade Partner Reviewed</th>
                </tr>
                {recordss.map((item, index) => (
                  <tr key={index}>
                    <td>{dateFormat(new Date(item?.tradeDate), 0)}</td>
                    <td>{item?.tradeNo}</td>
                    <td>{item?.targetName}</td>
                    <td>{item?.tradeAmount}</td>
                    <td>-</td>
                    <td>
                      <span
                        style={{
                          padding: "2px 15px",
                          backgroundColor:
                            item.tradePartnerConfirmation === "Approved"
                              ? "rgba(216, 247, 230, 1)"
                              : "rgba(74, 58, 255, 0.2)",
                          color:
                            item.tradePartnerConfirmation === "Approved"
                              ? "#00692A"
                              : "#4A3AFF",
                          borderRadius: "100px",
                          display: "block",
                          textAlign: "center",
                        }}
                      >
                        {item?.tradePartnerConfirmation === "Approved"
                          ? item.tradePartnerReview
                          : "Not approved"}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data.length !== 0 && (
              <>
                <nav
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    width: "100%",
                  }}
                >
                  <ul
                    style={{
                      display: "flex",
                      listStyleType: "none",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <li style={{ marginRight: "8px" }}>
                      <p
                        style={{
                          padding: "8px 12px",
                          borderRadius: "4px",
                          cursor: currentPage === 1 ? "not-allowed" : "pointer",
                          backgroundColor:
                            currentPage === 1 ? "#f0f0f0" : "white",
                        }}
                        onClick={handlePrevPage}
                      >
                        {"<"}
                      </p>
                    </li>
                    {/* Showing current page and total pages information */}
                    <li
                      style={{
                        marginRight: "8px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px",
                      }}
                    >
                      Showing {currentPage} of {totalPages}
                    </li>
                    <li style={{ marginRight: "8px" }}>
                      <p
                        style={{
                          padding: "8px 12px",
                          borderRadius: "4px",
                          cursor:
                            currentPage === totalPages
                              ? "not-allowed"
                              : "pointer",
                          backgroundColor:
                            currentPage === totalPages ? "#f0f0f0" : "white",
                        }}
                        onClick={handleNextPage}
                      >
                        {">"}
                      </p>
                    </li>
                  </ul>
                </nav>
              </>
            )}
          </div>
        </div>
      </Dash>
      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default ATTrades;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
