import React from "react";
import styled from "styled-components";

const Transactions = () => {
  const transactionsData = [
    {
      dealDate: "2024-07-22",
      borrower: "BHAVYA ROLLINGS MILLS LTD",
      period: "30",
      billValue: "3,109,589",
      discount: "20",
      loan: "2,487,671",
      interestRate: "18",
      interestAmount: "36,804",
      netPayment: "2,450,868",
      offerNo: "OFAD-PTS-PBY-FRCR-PTS-1536",
      paymentDetail: "Paid in full",
    },
    {
      dealDate: "2024-06-04",
      borrower: "Yazali Farmers Producer Company Limited",
      period: "30",
      billValue: "2,116,114",
      discount: "20",
      loan: "1,692,891",
      interestRate: "16",
      interestAmount: "22,263",
      netPayment: "1,670,628",
      offerNo: "OFAD-PTS-PBY-FRCR-PTS-1512",
      paymentDetail: "Partial payment",
    },
    {
      dealDate: "2024-09-12",
      borrower: "KOTARU LABORATORIES PRIVATE LIMITED",
      period: "60",
      billValue: "1,227,205",
      discount: "20",
      loan: "981,764",
      interestRate: "19",
      interestAmount: "30,663",
      netPayment: "950,800",
      offerNo: "OFAD-to_be_changed_in_futur1583",
      paymentDetail: "-",
    },
    {
      dealDate: "2024-08-16",
      borrower: "Sree Aditya Services",
      period: "30",
      billValue: "172,892",
      discount: "20",
      loan: "138,314",
      interestRate: "18",
      interestAmount: "2,046",
      netPayment: "136,267",
      offerNo: "OFAD-PTS-PBY-FRCR-PTS-1551",
      paymentDetail: "Paid in full",
    },
    {
      dealDate: "2024-09-24",
      borrower: "V1 FACILITY SERVICES",
      period: "15",
      billValue: "710,859",
      discount: "20",
      loan: "568,687",
      interestRate: "18",
      interestAmount: "4,207",
      netPayment: "564,480",
      offerNo: "OFAD-to_be_changed_in_futur1602",
      paymentDetail: "Pending",
    },
    {
      dealDate: "2024-09-25",
      borrower: "KOTARU",
      period: "60",
      billValue: "785,600",
      discount: "34.3",
      loan: "516,139",
      interestRate: "19",
      interestAmount: "16,121",
      netPayment: "500,018",
      offerNo: "OFAD-to_be_changed_in_futur1603",
      paymentDetail: "Partial payment",
    },
  ];

  return (
    <TransactionContainer>
      {/* Weighted Metrics Section */}
      <div className="weighted-metrics">
        <div className="main-title">Weighted Metrics</div>
        <div className="metrics-grid">
          <div>
            <div className="metric-header">Weighted ROI</div>
            <div className="metric-value">17.789333084305234</div>
          </div>
          <div>
            <div className="metric-header">Weighted Tenure (days)</div>
            <div className="metric-value">30.98</div>
          </div>
        </div>
      </div>

      {/* Transactions Table */}
      <div className="main-title">Transactions</div>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th className="sort-icon">Deal Date</th>
              <th className="sort-icon">Borrower</th>
              <th className="sort-icon">Period</th>
              <th className="sort-icon">Bill Value</th>
              <th className="sort-icon">Discount</th>
              <th className="sort-icon">Loan</th>
              <th className="sort-icon">Interest rate</th>
              <th className="sort-icon">Interest Amount</th>
              <th className="sort-icon">Net Payment</th>
              <th className="sort-icon">Payment detail</th>
              <th>Offer No</th>
            </tr>
          </thead>
          <tbody>
            {transactionsData.map((row, index) => (
              <tr key={index}>
                <td>{row.dealDate}</td>
                <td>{row.borrower}</td>
                <td>{row.period}</td>
                <td className="currency">{row.billValue}</td>
                <td>{row.discount}</td>
                <td className="currency">{row.loan}</td>
                <td>{row.interestRate}</td>
                <td className="currency">{row.interestAmount}</td>
                <td className="currency">{row.netPayment}</td>
                <td>{row.offerNo || "-"}</td>
                <td>{row.paymentDetail}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </TransactionContainer>
  );
};

export default Transactions;

const TransactionContainer = styled.div`
  .main-title {
    background: #a4c2f4;
    color: #000;
    text-align: center;
    padding: 10px;
    margin-bottom: 20px;
    font-weight: 500;
  }

  .weighted-metrics {
    margin-bottom: 20px;
  }

  .metrics-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .metric-header {
    background: #00b894;
    color: white;
    padding: 10px;
    text-align: center;
    font-weight: 500;
  }

  .metric-value {
    background: #f8f9fa;
    padding: 10px;
    text-align: center;
  }

  .table-container {
    overflow-x: auto;
    margin-top: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
    white-space: nowrap;
  }

  th {
    background: #00b894;
    color: white;
    padding: 8px 12px;
    text-align: left;
    font-weight: 500;
    white-space: nowrap;
  }

  td {
    padding: 8px 12px;
    background: #f8f9fa;
    border-bottom: 1px solid #e5e7eb;
  }

  tr:nth-child(even) td {
    background: #f0f0f0;
  }

  .currency::before {
    content: "₹ ";
  }

  .sort-icon::after {
    content: "↕";
    margin-left: 4px;
  }
`;
