import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { callAPI } from "../../API/FP/Actions";
import { contextObj, dateFormat, requestStates } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import { PlacedRequestInvoicesData } from "../../MockData/AnchorTraderMock";

function RequestOfferFullDetails({ setShowFullOfferDetails }) {
  const location = useLocation();
  const requestData = location.state?.requestData || {};
  const token = location.state?.authToken;
  const [tradeDetails, setTradeDetails] = useState([]);
  const [showButtons, setShowButtons] = useState(false);
  const [auth, setAuth] = useAuth();

  // console.log("Request Data", requestData)

  useEffect(() => {
    const fetchTradeData = async () => {
      try {
        const constructObj = contextObj("paisabuddy", "fetch", "tradesList");
        const tradesResponse = await callAPI(
          auth?.token,
          constructObj,
          "trades"
        );

        if (tradesResponse.res.status === 200) {
          const filteredData =
            tradesResponse.res.data.data.message.msgDetails.tradesList.filter(
              (trade) =>
                trade.financerequest?.id &&
                trade.financerequest.id === requestData.financerequest.id
            );
          setTradeDetails(filteredData);
        }
      } catch (err) {
        console.log("Error fetching data:", err);
      }
    };

    // fetchTradeData();
  }, [requestData, auth?.token]);

  let tableHeaderNames = [
    "Invoice Date",
    "Invoice Number",
    "Trade Partner",
    // "Anchor Trader",
    "Trade Amount (INR)",
    "Term (Days)",
  ];

  return (
    <>
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
              {" "}
              Request details
            </h3>
            <div className="record-details">
              <table style={{ width: "auto" }}>
                <tbody>
                  <tr>
                    <th>Date of Request</th>
                    <td>September 19, 2024 </td>
                  </tr>
                  <tr>
                    <th>Total trade value (INR)</th>
                    <td>
                      <NumericFormat
                        displayType="text"
                        value={288725}
                        thousandsGroupStyle="lakh"
                        thousandSeparator=","
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </RequestInformation>
        <div style={{ display: "flex" }}>
          <SideTables className="body-text" style={{ width: "50%" }}>
            <div className="main-content">
              <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                {" "}
                Disbursement details
              </h3>
              <div className="record-details">
                <table style={{ width: "auto" }}>
                  <tbody>
                    <tr>
                      <th>Margin Cut (%)</th>
                      <td>20 %</td>
                    </tr>
                    <tr>
                      <th>Margin value (INR)</th>
                      <td>57,745</td>
                    </tr>
                    <tr>
                      <th>Amount after margin (INR)</th>
                      <td>2,30,980</td>
                    </tr>
                    <tr>
                      <th>Annualized Interest rate (%)</th>
                      <td>18%</td>
                    </tr>
                    <tr>
                      <th>Interest value (INR)</th>
                      <td>6,834</td>
                    </tr>
                    <tr>
                      <th>Amount to be disbursed (INR)</th>
                      <td>2,24,146</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </SideTables>
          <SideTables className="body-text" style={{ width: "50%" }}>
            <div className="main-content">
              <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                {" "}
                Repayment details
              </h3>
              <div className="record-details">
                <table style={{ width: "auto" }}>
                  <tbody>
                    <tr>
                      <th>Repayment Tenure (Days)</th>
                      <td>60</td>
                    </tr>
                    <tr>
                      <th>Repayment to ProFinTech (INR)</th>
                      <td>2,88,725</td>
                    </tr>
                    <tr>
                      <th>Transaction charges (%)</th>
                      <td>0.70%</td>
                    </tr>
                    <tr>
                      <th>Transaction charge amount (INR)</th>
                      <td>332</td>
                    </tr>
                    <tr>
                      <th>Repayment to finance partner (INR)</th>
                      <td>2,30,980</td>
                    </tr>
                    <tr>
                      <th>Balance payment to you (INR)</th>
                      <td>57,413</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </SideTables>
        </div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}> Summary</h3>
            <div className="record-details">
              <table style={{ width: "auto" }}>
                <tbody>
                  <tr>
                    <th>Trade Value (INR)</th>
                    <td>2,88,725</td>
                  </tr>
                  <tr>
                    <th>Interest charges</th>
                    <td>6,834</td>
                  </tr>
                  <tr>
                    <th>Transaction charges</th>
                    <td>332</td>
                  </tr>
                  <tr>
                    <th>Net amount received by you</th>
                    <td>2,81,558</td>
                  </tr>
                  <tr>
                    <th>Effective Interest Rate</th>
                    <td>18.88%</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </RequestInformation>
        {!showButtons && (
          <div style={{ marginTop: "15px", textAlign: "center" }}>
            <button
              style={{
                borderRadius: "2px",
                backgroundColor: "#FFF",
                color: "#4A3AFF",
                border: "1px solid rgba(0, 0, 128, 0.20)",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "2px",
                height: "fit-content",
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                //   opacity: checkBoxValue === true ? "1" : "0.5",
              }}
              onClick={() => setShowFullOfferDetails(false)}
            >
              Back
            </button>
            <button
              style={{
                marginLeft: "10px",
                borderRadius: "2px",
                backgroundColor: "#4A3AFF",
                color: "white",
                border: "none",
                padding: "10px 20px",
                cursor: "pointer",
                borderRadius: "2px",
                height: "fit-content",
                boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                //   opacity: checkBoxValue === true ? "1" : "0.5",
              }}
              onClick={() => setShowButtons(true)}
            >
              Accept Offer
            </button>
          </div>
        )}
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestOfferFullDetails;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 45% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;

const Dash = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90% !important;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    // box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;

const SideTables = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
