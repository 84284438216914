import React from "react";

const Logout = ({ fill = "#4A3AFF" }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1293_9467)">
      <path
        d="M16 17.5H17V24.5H0V10C0 8.62 1.12 7.5 2.5 7.5H6.76C6.55 7.81 6.4 8.15 6.27 8.5H2.5C1.67 8.5 1 9.17 1 10V23.5H16V17.5ZM23.56 6.94L17.12 0.5L16.41 1.21L22.7 7.5H10.5C9.12 7.5 8 8.62 8 10V18.5H9V10C9 9.17 9.67 8.5 10.5 8.5H22.71L16.44 14.77L17.15 15.48L23.57 9.06C24.15 8.48 24.15 7.52 23.57 6.94H23.56Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_1293_9467">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Logout;
