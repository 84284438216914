// src/components/GSTDashboard.jsx
import React from 'react';
import styled from 'styled-components';
import BigNumberWidget from './widgets/BigNumberWidget';
import DonutChartWidget from './widgets/DonutChartWidget';
import MixedChartWidget from './widgets/MixedChartWidget';
import TableWidget from './widgets/TableWidget';

const GSTDashboard = ({setActiveIndex}) => {
  // Data for big number widgets
  setActiveIndex(3)
  const summaryData = {
    b2bSales: 4.61,
    b2cSales: 0.46,
    totalPurchases: 8.67,
    uniqueCustomers: 158
  };

  // Data for mixed chart (Sales and Purchases)
  const monthlyData = [
    { month: 'May', totalSales: 0.2, totalPurchases: 0.4, salesCount: 50, purchasesCount: 80 },
    { month: 'Jul', totalSales: 0.75, totalPurchases: 0.6, salesCount: 108, purchasesCount: 98 },
    { month: 'Sep', totalSales: 0.83, totalPurchases: 0.58, salesCount: 100, purchasesCount: 92 },
    { month: 'Nov', totalSales: 0.7, totalPurchases: 0.47, salesCount: 114, purchasesCount: 59 },
    { month: '2024', totalSales: 0.64, totalPurchases: 0.45, salesCount: 114, purchasesCount: 52 },
    { month: 'Mar', totalSales: 0.57, totalPurchases: 0.3, salesCount: 62, purchasesCount: 30 },
    { month: 'May', totalSales: 0, totalPurchases: 0, salesCount: 0, purchasesCount: 0 }
  ];

  // Data for region distribution donut chart
  const regionData = [
    { name: 'Telangana', value: 52.76 },
    { name: 'Andhra Pradesh', value: 37.47 },
    { name: 'Karnataka', value: 7.88 },
    { name: 'Bihar', value: 1.89 },
    { name: 'Kerala', value: 1.00 }
  ];

  const regionColors = ['#FF9F40', '#A78BFA', '#8B7355', '#D3D3D3', '#FF6B6B'];

  // Data for top customers donut chart
  const customerData = [
    { name: 'TECHLINE CLOTHING', value: 43.20 },
    { name: 'SUBBAIAH HOSPITAL MEDICAL', value: 8.35 },
    { name: 'HILLS FASHION', value: 7.74 },
    { name: 'Customer 4', value: 6.44 },
    { name: 'Customer 5', value: 6.19 },
    { name: 'Customer 6', value: 6.14 },
    { name: 'Customer 7', value: 6.13 },
    { name: 'Customer 8', value: 5.54 },
    { name: 'Customer 9', value: 5.24 },
    { name: 'Customer 10', value: 5.03 }
  ];

  const customerColors = ['#FF6B6B', '#4FD1C5', '#A78BFA', '#63B3ED', '#F6AD55', '#68D391', '#FC8181', '#B794F4', '#66DAE3', '#7F9CF5'];

  // Table data for Trade Partners
  const topCompaniesColumns = [
    { key: 'name', label: 'gstr1_2000' },
    { key: 'sales', label: 'Total Sales ₹' }
  ];

  const topCompaniesData = [
    { name: 'Krishna Institute of Medical S', sales: '₹ 0.0794' },
    { name: 'CANARA BANK', sales: '₹ 0.0357' },
    { name: 'HealthCare Global Enterprises', sales: '₹ 0.0028' }
  ];

  return (
    <DashboardContainer>
      <DashboardSection>
        <DashboardTitle>GST Business Analysis</DashboardTitle>
        
        {/* Anchor Trader Details */}
        <CardContainer>
          <SectionTitle>Anchor Trader Details</SectionTitle>
          <TableWidget
            columns={[
              { key: 'name', label: 'AT Name' },
              { key: 'gst', label: 'GST ID' },
              { key: 'date', label: 'Last Updated Date' },
              { key: 'sales', label: 'Total Sales' }
            ]}
            data={[
              {
                name: ' Anchor Trader',
                gst: '36AAFP368S',
                date: '2024-07-12',
                sales: '₹ 5.07'
              }
            ]}
          />
        </CardContainer>

        {/* Summary Numbers */}
        <SummaryGrid>
          <BigNumberWidget number={`₹ ${summaryData.b2bSales}`} label="Total B2B Sales" color="#4F46E5" />
          <BigNumberWidget number={`₹ ${summaryData.b2cSales}`} label="Total B2C Sales" color="#4F46E5" />
          <BigNumberWidget number={`₹ ${summaryData.totalPurchases}`} label="Total Purchases" color="#4F46E5" />
          <BigNumberWidget number={summaryData.uniqueCustomers} label="Unique Customers (B2B)" color="#4F46E5" />
        </SummaryGrid>

        {/* Charts Row */}
        <ChartsGrid>
          <MixedChartWidget data={monthlyData} />
          <DonutChartWidget
            data={regionData}
            dataKey="value"
            nameKey="name"
            colors={regionColors}
            title="Total Sales by Buyers Region (B2B)"
          />
        </ChartsGrid>

        {/* Bottom Charts */}
        <ChartsGrid>
          <DonutChartWidget
            data={customerData}
            dataKey="value"
            nameKey="name"
            colors={customerColors}
            title="Top Customer by Amount (B2B)"
          />
          <DonutChartWidget
            data={customerData}
            dataKey="value"
            nameKey="name"
            colors={customerColors}
            title="Top Procurement (B2B)"
          />
        </ChartsGrid>

        {/* Bottom Tables */}
        <ChartsGrid>
          <TableSection>
            <SectionTitle>Trade Partners(Customers) in Top 2000 Companies</SectionTitle>
            <TableWidget columns={topCompaniesColumns} data={topCompaniesData} />
          </TableSection>
          <TableSection>
            <SectionTitle>Trade Partners(Procurement's) in Top 2000 Companies</SectionTitle>
            <TableWidget columns={topCompaniesColumns} data={topCompaniesData} />
          </TableSection>
        </ChartsGrid>
      </DashboardSection>
    </DashboardContainer>
  );
};

// Styled Components
const DashboardContainer = styled.div`
  padding: 1.5rem;
  background-color: #F9FAFB;
  min-height: 100vh;
`;

const DashboardSection = styled.div`
  margin-bottom: 1.5rem;
`;

const DashboardTitle = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 1.5rem;
`;

const CardContainer = styled.div`
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  margin-bottom: 1.5rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.125rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 1rem;
`;

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const ChartsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const TableSection = styled(CardContainer)`
  margin-bottom: 0;
`;

export default GSTDashboard;