// src/components/widgets/DonutChartWidget.jsx
import React from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const DonutChartWidget = ({ 
  data, 
  dataKey, 
  nameKey,
  colors, 
  title 
}) => {
  // Process data to add shortened names
  const processedData = data.map((item, index) => ({
    ...item,
    shortName: index < 3 ? item.name : `C${index - 2}` // First 3 keep names, rest get C1, C2, etc.
  }));

  // Custom Legend
  const CustomLegend = ({ payload }) => (
    <LegendContainer>
      {payload.map((entry, index) => (
        <LegendItem key={index}>
          <LegendColor color={entry.color} />
          <LegendText>
            <LegendName>{processedData[index].name}</LegendName>
            <LegendValue>{(processedData[index].value).toFixed(1)}%</LegendValue>
          </LegendText>
        </LegendItem>
      ))}
    </LegendContainer>
  );

  // Simple percentage label for the chart
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    
    // Only show labels for segments > 5%
    if (percent < 0.05) return null;

    return (
      <text
        x={x}
        y={y}
        fill="#FFFFFF"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize="14"
        fontWeight="600"
      >
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    );
  };

  return (
    <ChartContainer>
      <ChartTitle>{title}</ChartTitle>
      <ChartContent>
        <div style={{ width: '60%', height: '400px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart>
              <Pie
                data={processedData}
                cx="50%"
                cy="50%"
                innerRadius={45}
                outerRadius={145}
                paddingAngle={3}
                dataKey={dataKey}
                nameKey="shortName"
                label={renderCustomizedLabel}
                labelLine={false}
              >
                {processedData.map((entry, index) => (
                  <Cell 
                    key={`cell-${index}`} 
                    fill={colors[index % colors.length]}
                    strokeWidth={1}
                    stroke="#fff"
                  />
                ))}
              </Pie>
              <Tooltip
                formatter={(value, name, props) => [
                  `₹ ${value.toFixed(2)}`,
                  props.payload.name
                ]}
                contentStyle={{
                  backgroundColor: 'white',
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
                  padding: '12px 16px',
                  fontSize: '14px',
                  fontWeight: '500'
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <CustomLegend payload={processedData.map((entry, index) => ({
          value: entry.name,
          color: colors[index % colors.length]
        }))} />
      </ChartContent>
    </ChartContainer>
  );
};

// Styled Components
const ChartContainer = styled.div`
  background: white;
  border-radius: 12px;
  padding: 2rem;
  height: 100%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.08);
`;

const ChartTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  color: #1F2937;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const ChartContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;

const LegendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 40%;
  max-height: 400px;
  overflow-y: auto;
  padding-right: 1rem;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.2s;

  &:hover {
    background-color: #F3F4F6;
  }
`;

const LegendColor = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background-color: ${props => props.color};
  flex-shrink: 0;
`;

const LegendText = styled.div`
  flex: 1;
  min-width: 0;
`;

const LegendName = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1F2937;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const LegendValue = styled.div`
  font-size: 12px;
  color: #6B7280;
`;

export default DonutChartWidget;