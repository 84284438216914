import React, { useState } from "react";
import styled from "styled-components";
import {
  Button,
  TextField,
  Snackbar,
  Alert,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import ProFinTechLogoCompressed from "../images/ProFinTechLogoCompressed.png";

function SetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const navigate = useNavigate();

  const validatePassword = (pwd) => {
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    return pwd.length >= 8 && hasNumber.test(pwd) && hasSpecialChar.test(pwd);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      setOpenSnackbar(true);
      setApiResultMessage(
        "Password must be at least 8 characters long and contain at least one number and one special character."
      );
      setAlertSeverity("error");
      return;
    }

    if (password !== confirmPassword) {
      setOpenSnackbar(true);
      setApiResultMessage("Passwords do not match.");
      setAlertSeverity("error");
      return;
    }

    console.log("Password:", password);
    console.log("Confirm Password:", confirmPassword);
    setOpenSnackbar(true);
    setApiResultMessage("Password Set Successfully.Login Again");
    setAlertSeverity("success");
    setTimeout(() => {
      navigate("/");
    }, 500);
  };

  return (
    <>
      <Sign1>
        <div className="Logo">
          <img src={ProFinTechLogoCompressed} alt="Logo" />
        </div>
        <div className="Sign1">
          <div className="parentDiv">
            <div
              style={{
                color: "black",
                fontSize: 25,
                fontFamily: "Open Sans",
                fontWeight: "700",
                wordWrap: "break-word",
                marginBottom: "20px",
              }}
            >
              Account Registration
            </div>
            <form className="form" onSubmit={handleSubmit}>
              <div className="formwrap">
                <div className="TotalSection">
                  <div className="secondSection">
                    <div className="content">
                      <label
                        style={{
                          width: "100%",
                          color: "#333333",
                          fontSize: 18,
                          fontFamily: "Open Sans",
                          fontWeight: "600",
                          wordWrap: "break-word",
                        }}
                      >
                        Enter New Password{" "}
                        <span style={{ color: "#D20319" }}>*</span>
                      </label>
                      <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        id="password"
                        name="password"
                        required
                        placeholder="Enter a New Password"
                      />
                      <PasswordStrengthBar password={password} />
                    </div>

                    <div className="content">
                      <label
                        style={{
                          width: "100%",
                          color: "#333333",
                          fontSize: 18,
                          fontFamily: "Open Sans",
                          fontWeight: "600",
                          wordWrap: "break-word",
                        }}
                      >
                        Confirm New Password{" "}
                        <span style={{ color: "#D20319" }}>*</span>
                      </label>
                      <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        id="confirmPassword"
                        name="confirmPassword"
                        required
                        placeholder="Confirm Password"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="ButtonsPlacement">
                <button type="submit">Save</button>
              </div>
            </form>
          </div>
        </div>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {apiResultMessage}
          </Alert>
        </Snackbar>
      </Sign1>
    </>
  );
}

export default SetPassword;

const Sign1 = styled.div`
  .Sign1 {
    display: flex;
    margin-bottom: 5vh;
    justify-content: center;
    body {
      background-color: #e5e4e2;
    }
  }
  /* Styles for the page background */

  /* Styles for the form wrapper */
  .parentDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .ButtonsPlacement {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
  }
  .formwrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    width: fit-content;
  }
  .Logo {
    margin: 10px 30px;
  }

  /* Styles for the welcome text */
  .formwrap > div:first-child {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .content {
    margin: 10px;
  }
  /* Styles for the form */
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .TotalSection {
    display: flex;
    flex-direction: row;
    width: 530px;
    height: 350px;
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
  .secondSection {
    display: flex;
    flex-direction: column;
  }
  /* Styles for the form labels */
  label {
    font-weight: 600;
    margin-bottom: 5px;
    display: flex;
    color: #333333;
    font-size: 18px; /* Added 'px' */
    font-family: "Open Sans";
  }

  /* Styles for the form inputs */
  input {
    height: 30px;
    width: 300px;
    padding: 5px;
  }

  /* Styles for the submit button */
  button[type="submit"] {
    font-size: 18px;
    background-color: #297fff;
    font-family: "Open Sans";
    font-weight: 400; /* Remove quotes */
    color: white;
    height: 45px;
    width: 120px;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    align-self: flex-end; /* Corrected */
  }

  /* Styles for the "have an account?" and "Login for free" links */
  div > a {
    margin-top: 10px;
    text-decoration: none;
    color: orange;
  }

  /* Optional hover effect for links */
  div > a:hover {
    text-decoration: underline;
  }
`;
