import { Checkbox, Input, TextField, TextareaAutosize } from "@mui/material";
import React, { useState } from "react";

function ProfileERP() {
  const ariaLabel = { "aria-label": "description" };
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [checkBoxValue, setCheckboxValue] = useState(false);
  return (
    <div className="body-text">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <div
            style={{ color: "#4A3AFF", fontWeight: "bold", marginTop: "20px" }}
          >
            Process Of Installation:
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <ul>
                <li>The software will be installed in your ERP system.</li>
                <li>
                  There will be plugins, and pre-requisites to be installed.
                </li>
                <li>
                  We shall assist you in the process, which will take 1-2 days,
                  after your consent for access.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "2%" }}>
          <div>
            <div
              style={{
                color: "#4A3AFF",
                fontWeight: "bold",
              }}
            >
              Data security and access:
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "15px",
              }}
            >
              <div>The data is completely safe at ProFinTech.</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <ul>
                  <li>Fully secure and full encryption of data.</li>
                  <li>
                    Explicit consent or approval will be taken from the
                    customer.
                  </li>
                  <li>
                    Agreement will be signed with the customer to ensure that
                    there is formal alignment.
                  </li>
                  <li>
                    Personal data, customer pricing, product details will never
                    be shared with any 3rd party.
                  </li>
                  <li>Developed and deployed on AWS cloud.</li>
                </ul>
              </div>
              <div>
                Please provide your consent for accessing your ERP Data.
              </div>
            </div>
          </div>
        </div>

        {/* <h4
          style={{ marginBottom: "5px", marginTop: "60px", color: "#4A3AFF" }}
        >
          {" "}
          <Checkbox
            {...label}
            value={checkBoxValue}
            onChange={() => setCheckboxValue(!checkBoxValue)}
          />
          I hereby declare that the information given above and in the enclosed
          documents is true to the best of my knowledge and belief. Nothing has
          been concealed therein. I acknowledge and accept the terms and
          conditions associated with this agreement.
        </h4> */}
        <div style={{ marginTop: "15px", textAlign: "center" }}>
          <button
            style={{
              borderRadius: "2px",
              backgroundColor: "#FFF",
              color: "#4A3AFF",
              border: "1px solid rgba(0, 0, 128, 0.20)",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "2px",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
              //   opacity: checkBoxValue === true ? "1" : "0.5",
            }}
            //   onClick={() => navigate("/request_log")}
          >
            Reject Access
          </button>
          <button
            style={{
              marginLeft: "10px",
              borderRadius: "2px",
              backgroundColor: "#4A3AFF",
              color: "white",
              border: "none",
              padding: "10px 20px",
              cursor: "pointer",
              borderRadius: "2px",
              height: "fit-content",
              boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
              //   opacity: checkBoxValue === true ? "1" : "0.5",
            }}
            //   onClick={() => navigate("/request_log")}
          >
            Allow Access
          </button>
        </div>
      </div>
    </div>
  );
}

export default ProfileERP;
