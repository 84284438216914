import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumbs, Divider, Link, Typography } from "@mui/material";
import CircleCustom from "../../Common/CircleCustom";
import {
  callAPI,
  getRepaymentData,
  getTransactionData,
} from "../../API/FP/Actions";
import { contextObj, dateFormat } from "../../Common/Constants";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";

function RequestRepayment({ statusVal }) {
  // const { state } = useLocation();
  // let navigate = useNavigate();
  const location = useLocation();
  const requestData = location.state?.requestData;
  const token = location.state?.authToken;
  const [repaymentDetails, setRepaymentDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [utrNumbers, setUtrNumber] = useState("");
  const [auth, setAuth] = useAuth();

  const fetchTransactionData = async (trxId, token) => {
    try {
      const transactionResponse = await getTransactionData(auth?.token);
      console.log("TR : ", transactionResponse);
      if (
        transactionResponse &&
        transactionResponse.res &&
        transactionResponse.res.data
      ) {
        const filteredTransactions = transactionResponse.res.data.filter(
          (transaction) =>
            transaction.repayment?.collectionTrnxDetailsId &&
            transaction.repayment.collectionTrnxDetailsId === trxId
        );

        if (filteredTransactions.length > 0) {
          // Concatenate all UTR numbers into a single string
          const utrNumbers = filteredTransactions
            .map((t) => t.utrNo)
            .join(", ");
          console.log("UTR : ", utrNumbers);
          setUtrNumber(utrNumbers);
        } else {
          setUtrNumber("Not available"); // Set this if no matching transactions are found
        }
      }
    } catch (error) {
      console.error("Error fetching transaction data: ", error);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // if (!repaymentDetails || repaymentDetails.length === 0) {
  //   return <div>No repayment data available</div>;
  // }

  const repayment = repaymentDetails[0];

  let tableHeaderNames = [
    "Finance request ID",
    "Amount to be disbursed",
    "Creditors Account Name",
    "Virtual Account number",
    "IFSC code",
  ];
  let tableData = [
    {
      financeRequestId: "REQ-2023-123456",
      amountDisbursed: "8,80,000",
      creditorsAccountName: "Paisabuddy Escrow account",
      virtualAccountNumber: "PBD110000000001",
      ifscCode: "IDFB0080202",
    },
  ];

  return (
    <>
      <div>
        <RequestInformation className="body-text">
          <div className="main-content">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3
                style={{
                  marginBottom: "5px",
                  color: "#4A3AFF",
                  marginTop: "0px",
                }}
                className="section-heading"
              >
                {" "}
                Escrow details
              </h3>
            </div>
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Finance request ID</th>
                    <td>REQ-2023-123456</td>
                  </tr>
                  <tr>
                    <th>Amount to be Repaid (INR)</th>
                    <td>2,88,725</td>
                  </tr>
                  <tr>
                    <th>Current status</th>
                    <td>
                      {statusVal == "PENDING_REPAYMENT"
                        ? "Repayment Pending"
                        : "Repayment Done"}
                    </td>
                  </tr>
                  <tr>
                    <th>Repayment due date</th>
                    <td>October 22, 2024</td>
                  </tr>
                  <tr>
                    <th>Destination Account Number</th>
                    <td>PT11000000002</td>
                  </tr>
                  <tr>
                    <th>Destination Account Name</th>
                    <td>Prime Textiles Escrow Account</td>
                  </tr>

                  <tr>
                    <th>Destination account IFSC</th>
                    <td>IDFPT000002</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
          </div>
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestRepayment;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 80% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
  }
  .main-content {
    margin-top: 30px;
  }
`;
