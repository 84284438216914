import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import Footer from "../Common/Footer";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  callAPI,
  getOpenRequests,
  getPFTCreditRecommendationScores,
} from "../API/FP/Actions";
import { getToken } from "../API/Common/Actions";
import FinanceRequestIndividual from "./FinanceRequestIndividual";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../context/auth";
import { contextObj } from "../Common/Constants";
import { OpenRequestsMockData } from "../MockData/FinancePartnerMock";

function FinanceRequestInformation({ setActiveIndex }) {
  setActiveIndex(1);
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState("");
  const [auth, setAuth] = useAuth();
  const [openRequestsApiData, setOpenRequestsApiData] =
    useState(OpenRequestsMockData);
  const [showSingleRequestDetails, setShowSingleRequestDetails] =
    useState(false);
  const [currentRequestDetails, setCurrentRequestDetails] = useState({});
  const [creditScoreReportCheck, setCreditScoreReportCheck] =
    React.useState(false);
  const [presentIndividualAssessment, setPresentIndividualAssessment] =
    useState([]);

  const creditScoreColor = (score) => {
    if (score > 70) {
      return "#00692A";
    } else {
      return "#D20319";
    }
  };

  const fetchToken = useCallback(async () => {
    try {
      const oResponse = await getOpenRequests(auth?.token);

      if (oResponse.res.status === 200) {
        setOpenRequestsApiData(oResponse.res.data);
      }
      const tResponse = await getPFTCreditRecommendationScores(auth?.token);
      if (tResponse.res.data.length !== 0) {
        setPresentIndividualAssessment(tResponse.res.data);
      }
    } catch (err) {
      console.log("Error", err);
    }
  }, []);

  useEffect(() => {
    // fetchToken();
  }, []);

  function addDays(date, days) {
    date.setDate(date.getDate() + days);
    let month = date.toLocaleString("default", { month: "short" }); //months from 1-12
    let day = date.getUTCDate();
    let year = date.getUTCFullYear();
    return month + " " + day + ", " + year;
  }

  const getCreditScore = (gst) => {
    let t = presentIndividualAssessment.filter(
      (item) => item.tradePartnerGST === gst
    );
    return t[0]?.creditScore;
  };
  return (
    <>
      <Finance1>
        <div>
          <div
            role="presentation"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              color: "#4A3AFF",
              marginTop: "30px",
            }}
          >
            {!creditScoreReportCheck && (
              <Breadcrumbs aria-label="breadcrumb">
                {/* <Link
                  underline="hover"
                  color="rgba(74, 58, 255, 0.5)"
                  // href="/financesdashboard"
                  onClick={() => {
                    navigate("/creditbazaar/financesdashboard");
                  }}
                  className="custom-link clickable"
                >
                  Dashboard
                </Link> */}
                {!showSingleRequestDetails ? (
                  <Typography color="#4A3AFF">Open Requests</Typography>
                ) : (
                  <Link
                    underline="hover"
                    color="rgba(74, 58, 255, 0.5)"
                    // href="/offer_information"
                    onClick={() => {
                      // navigate("/offer_information");
                      setShowSingleRequestDetails(false);
                    }}
                    className="custom-link clickable"
                  >
                    Open Requests
                  </Link>
                )}
                {showSingleRequestDetails && (
                  <Typography color="#4A3AFF">
                    {currentRequestDetails?.requestOfferRefNo ?? ""}
                  </Typography>
                )}
              </Breadcrumbs>
            )}

            {!creditScoreReportCheck && (
              <div className="header">
                <h3 style={{ margin: "0px" }} className="section-heading">
                  {showSingleRequestDetails
                    ? "Place an offer"
                    : "Open Requests"}
                </h3>
                {showSingleRequestDetails && (
                  <Typography style={{ fontSize: "15px" }}>
                    To participate in this request, make an offer below.
                  </Typography>
                )}
              </div>
            )}
          </div>
        </div>
      </Finance1>

      {!showSingleRequestDetails ? (
        <Dash className="body-text">
          <div className="main-content">
            <div className="record-details">
              <table>
                <tbody>
                  <tr>
                    <th>Request ID</th>
                    <th>Credit Score</th>
                    <th>Trade Value (INR)</th>
                    <th> Anchor Trader</th>
                    <th>Trade Partner</th>
                    <th>Action By Date</th>
                    <th>Details</th>
                  </tr>

                  {openRequestsApiData
                    .filter((item) => item.status === "OPEN_OFFER")
                    .map((val, ind) => {
                      return (
                        <tr key={ind}>
                          <td>{val?.requestOfferRefNo}</td>
                          <td>
                            <span
                              style={{
                                // color: creditScoreColor(
                                //   getCreditScore(val?.tradePartnerGst)
                                // ),
                                fontWeight: "700",
                              }}
                            >
                              {val?.creditScore}/100
                            </span>
                          </td>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={val?.tradeValue}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                          </td>
                          <td>
                            {val?.anchorTraderName.length > 16 ? (
                              <Tooltip
                                title={`${val?.anchorTraderName}`}
                                placement="top"
                              >
                                <div
                                  className={
                                    `${val?.anchorTraderName}`.length > 16 &&
                                    `text-ellipse`
                                  }
                                >
                                  {val?.anchorTraderName}
                                </div>
                              </Tooltip>
                            ) : (
                              <div
                                className={
                                  `${val?.anchorTraderName}`.length > 16 &&
                                  `text-ellipse`
                                }
                              >
                                {val?.anchorTraderName}
                              </div>
                            )}
                          </td>
                          <td>
                            {val?.tradePartnerName.length > 16 ? (
                              <Tooltip
                                title={`${val?.tradePartnerName}`}
                                placement="top"
                              >
                                <div
                                  className={
                                    `${val?.tradePartnerName}`.length > 16 &&
                                    `text-ellipse`
                                  }
                                >
                                  {val?.tradePartnerName}
                                </div>
                              </Tooltip>
                            ) : (
                              <div
                                className={
                                  `${val?.tradePartnerName}`.length > 16 &&
                                  `text-ellipse`
                                }
                              >
                                {val?.tradePartnerName}
                              </div>
                            )}
                          </td>
                          <td>
                            {/* {addDays(new Date(val?.financeRequestDate), 2)} */}
                            {val?.financeRequestDate}
                          </td>
                          <td>
                            <button
                              style={{
                                borderRadius: "2px",
                                backgroundColor: "#4A3AFF",
                                color: "white",
                                border: "none",
                                padding: "5px 5px",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowSingleRequestDetails(true);
                                setCurrentRequestDetails(val);
                              }}
                            >
                              View Request
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </Dash>
      ) : (
        <FinanceRequestIndividual
          data={currentRequestDetails}
          actionDateFunction={addDays}
          creditScoreColor={creditScoreColor}
          creditScoreReportCheck={creditScoreReportCheck}
          setCreditScoreReportCheck={setCreditScoreReportCheck}
          getCreditScore={getCreditScore}
          authToken={authToken}
        />
      )}
      <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div>
    </>
  );
}

export default FinanceRequestInformation;

const Finance1 = styled.div`
  .custom-link:hover {
    color: #4a3aff;
  }
  .btn {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
    font-size: 20px;
    // margin-top: 20vh;
  }
  .btn1 {
    color: white;
    background-color: orange;
    padding: 10px 20px;
    text-decoration: none;
    font-size: 20px;
    border-radius: 5px;
    display: inline-block;
    margin-left: 20px;
  }
  .btn:hover {
    background-color: #0056b3;
  }

  .content {
    margin-left: 20px;
    margin-top: 20px;
    display: flex;
  }
  .offer-card {
    border: 2px solid black;
    // width: 170%;
    margin-bottom: 20px;
  }
  .lender-details {
    margin-left: 40px;
  }

  .header {
    font-size: 18px;
    margin-top: 15px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    // width: 300%;
    border-collapse: collapse;
    border-left: 1px solid #dddddd;
    border-right: 1px solid #dddddd;
    border-top: 1px solid #dddddd;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #dddddd;
  }
  th {
    background-color: #f8f9fa;
    font-weight: bold;
  }
  .search-bar {
    display: flex;
    margin-bottom: 20px;
    margin-right: 10px;
  }
  .search-input {
    flex: 1;
    padding: 8px;
    border: 1px solid #dddddd;
    border-radius: 4px 0 0 4px;
    font-size: 14px;
    align-items: center;
  }
  .search-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 8px;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  .search-button:hover {
    background-color: #0056b3;
  }
  .popup {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  .popup .popuptext {
    width: 700px;
    visibility: hidden;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 6px;
    padding: 8px 0;
    position: absolute;
    z-index: 1;
    left: 50%;
    margin-left: -80px;
    overflow-y: auto;
    border-width: 3px;
    border-style: solid;
    border-color: #000;
  }
  .popup .popuptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  .popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
  }
  .popup .popuptext .btn {
    visibility: hidden;
  }
  .btn5 {
    color: white;
    background-color: #007bff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    display: inline-block;
    margin-left: 65vh;
    margin-bottom: 15px;
  }
  .btn5:hover {
    background-color: #0056b3;
  }
  @-webkit-keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Dash = styled.div`
  body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
  }

  .content {
    display: flex;
    margin-top: 20px;
  }

  .main-content {
    display: flex;
    padding: 20px 0px 10px 0px;
    flex-direction: column;
  }
  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    background: rgba(74, 58, 255, 0.1);
  }
  .record-details {
  }
`;
